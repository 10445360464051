<template>
  <div class="timerpartent">

  <div class="timer" v-bind:class="{ timerDone: timerDone }">{{ formatedCountdown || "countdown over" }}</div>
</div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>

  import * as moment from "moment";
import "moment-duration-format";


export default {
  name: 'TimerClockItem',

   props: {
      pause: Number,
      penalty: Boolean
     
    },
  data() {
    return {
      countdown: 16200,
       timerDone: false
    };
  },
  mounted() {
    this.countdown = this.$parent.gameSettings.timer
     setInterval(() => {
      if (this.pause == 0){
      this.countdown += 1;
      this.$emit('updateTimer', this.countdown)

      if (this.penalty === true){
        this.countdown += this.$parent.gameSettings.timerPenalty;
        this.$emit('penaltyDone',"")
      }
    }
      if (this.countdown >= this.$parent.gameSettings.timerDone ) { 
        console.log("count down done")
        this.timerDone = true
       // clearInterval(stopCountdown)
      }
    }, 1000);
  },
  computed: {
    formatedCountdown() {
      return moment.duration(this.countdown, "seconds").format("H:mm:ss", { trim: false });
    },
  },
};
</script>