<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame ">
          <div class="innerframe extrapadding">
            <div v-html="gameData.body"></div>
          </div>
          <div>
            <div class="container encpanel midClueText">
              <div class="midClueTextStart">{{ gameData.start }} </div>
              <input class="input "  v-bind:class="{ greenAnswer: questionok, redAnswer: questionwrong }" style="text-transform: uppercase;" placeholder="????" v-model="letter1"
                ref="firstbox"
                v-on:keyup=" makeUpper(0);" />
                <div class="midClueTextEnd">{{ gameData.end }} </div>
            </div>

          </div>
          <div class="field field--button btnwidth shortmargin">
            <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
              @click="checkcode()">{{ gameData.button }}</button>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'

export default {
  name: 'encryptionGame',
  props: {
    msg: String,
    dbid: String,
    gameName: String
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName]
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": "encryptionStart", "dbid": this.dbid })
    this.$emit('pauseTimer', 0)

    this.$refs.firstbox.focus()
    //  axios
    //  .post('http://192.168.1.224:3000/updatestage', {"stage": 7})
    //  setTimeout(() => {  this.GameMove() }, 120000);

  },
  data() {
    return {
      letter1: "",
      questionok: false,
      questionwrong: false,
      randNumber: [],
      gameData: ""
    }
  },
  methods: {
    makeUpper(number) {
      if (number == 0) {
        this.letter1 = this.letter1.toUpperCase()
      }
  
    },
    async checkcode() {

      if (this.letter1.toLowerCase() == this.gameData.answer ) {
        console.log("pass")
        axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName+"End", "dbid": this.dbid })
        this.$emit('updatestage', "")
      } else {
        console.log("error")
        axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName, "dbid": this.dbid })
        this.questionwrong = true
      }
    },
  
  }
}
</script>



