<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame videobackground">
 <video ref="videoPlayer" autoplay class="videoPlayer">
            <source
              :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['finalHackerVideo']"
              type="video/mp4"
            />
            <track :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['finalHackerVideoSubs']" kind="subtitles" srclang="en" label="English" default>
          </video>
          </div>
        </div>
    </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>


<script>
   import axios from 'axios'
   
  export default {
    name: 'finalHacker',
    props: {
      msg: String,
      dbid: String,
      videoData: Object
    },
     async mounted () {

    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor 


// this.videoData[$parent.videoPath][$parent.chosenLanguage]['finalHackerVideo']
      console.log("=============")
      console.log("finalhacker")
    

      this.$emit('pauseTimer', 1)

      setTimeout(() => {  this.GameMove() }, this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage]['finalHackerVideoTime']);
     },
    data () {
     return {
    }
  },
  methods: {
    GameMove() {
         axios
         .post(process.env.VUE_APP_ip+'/updatestage', {"stage": 12, "dbid": this.dbid})
    }
  }
}
</script>


