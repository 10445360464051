<template>
  <div class="backgroundLogoCont">


    <!-- <img alt="Vue logo"  class="backgroundFull"> -->
       
  </div>
</template>
  
<style>
@import '../assets/form.css';
</style>
  
  