export async function setup() {
  const logoData = [];
  //Default English
  logoData['zf'] = require("../assets/zf.png")
  logoData["dxr"] = require("../assets/datadoom.png")
  logoData["datadoomui"] = require("../assets/datadoomui.png")
  logoData["dxrlogo"] = require("../assets/dxrlogo.png")
  logoData["ecrc"] = require("../assets/ecrc.jpg")
  logoData["easterCyber"] = require("../assets/easterCyber.png")
  return logoData;
}
