<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <template v-if="this.$parent.gameSettings.prizeVideo == 'yes'">
            <div class="frame videobackground">
           <video ref="videoPlayer" autoplay class="videoPlayer">
            <source
              :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['endGameVideo']"
              type="video/mp4"
            />
            <track :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['endGameVideoSubs']" kind="subtitles" srclang="en" label="English" default>
          </video>
          </div>

        </template>
        <template v-else>
    
        <div class="frame videobackground">
           <video ref="videoPlayer" autoplay class="videoPlayer">
            <source
              :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['endGameVideoNoPrize']"
              type="video/mp4"
            />
            <track :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['endGameVideoSubsNoPrize']" kind="subtitles" srclang="en" label="English" default>
          </video>
          </div>
            </template>
        </div>
    </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
   import axios from 'axios'

  export default {
    name: 'endGame',
    props: {
      timer: Number,
      penaltyCount: Number,
      teamName: String,
      dbid: String,
      videoData: Object

    },
     async mounted () {
      document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor 
      console.log("endGame")
      this.$emit('pauseTimer', 1)
       axios.post(process.env.VUE_APP_ip+'/finalbox', {})

      this.endStage = 14;

       if (this.$parent.gameSettings.trainingVideo == "yes"){
        this.endStage = 13
       }

       var videoTime = 0
       if (this.$parent.gameSettings.prizeVideo == "yes"){
        videoTime = this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage]['endGameVideoTime']
       } else {
         videoTime = this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage]['endGameVideoTimeNoPrize']
       }
        
      setTimeout(() => { 
       axios.post(process.env.VUE_APP_ip+'/endgame', {"timer": this.timer, "penalties": this.penaltyCount, "teamName": this.teamName, "dbid": this.dbid, "stage": this.endStage}) 
        // this.$emit('reset', "reset")

     }, videoTime);

     },
    data () {
     return {
     endStag3: 13
    }
  },
  methods: {
   
  }
}
</script>


