import axios from "axios";

export async function callApi(endpoint, license, postData = "") {
  if (postData == "") {
    postData = JSON.stringify({
      gameTitle: process.env.VUE_APP_gameName,
      license: license,
    });
  } else {
    postData.gameTitle = process.env.VUE_APP_gameName;
    postData.license = license;
  }

  console.log(postData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: process.env.VUE_APP_ip + "/" + endpoint,
    headers: {
      "x-api-key": process.env.VUE_APP_token,
      "Content-Type": "application/json",
    },
    data: postData,
  };

  var apiData = (await axios.request(config)).data;

  console.log(apiData);
  return apiData;
}
