<template>
	<div>
    <template v-if="'customLeftLogo' in this.$parent.gameSettings">
        <img alt="Xcapism Learning" :src="$parent.logoData[$parent.gameSettings.customLeftLogo]" height="70" class="cornerlogo">
    </template>
    <template v-else>
          <img alt="Xcapism Learning" src="../assets/logo.png" height="70" class="cornerlogo">
    </template>

	</div>
	<div>

		<div v-if="this.$parent.gameSettings.dev == true" class="devTools inline">
			<div class="inline"><button class="" type="submit" @click="PreviousStage()">Previous</button></div>
			<div class="inline"><p>{{this.$parent.stage}}</p></div>
			<button class="" type="submit" @click="NextStage()">Next</button>

		</div>
		

		<img v-if="this.$parent.gameSettings.showRightLogo" alt="Xcapism Learning"
			:src="$parent.logoData[$parent.gameSettings.cornerLogoFile]" height="70" class="cornerlogoRight">
	</div>
</template>
<script>

  export default {
    name: 'DevTools',
    props: {
      msg: String,
      dbid: String,
      text: String,
      button: String
    },
    data () {

  },
  mounted () {
 
   
  },
  methods: {
    NextStage() {
      this.$emit('updatestage', "")
    },
	PreviousStage() {
      this.$emit('previousstage', "")
    }
  }
}
</script>