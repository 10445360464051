<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe extrapadding">
            <div v-html="$parent.language[this.gameName].body"></div>
          </div>
          <div>
            <div
              v-bind:class="{ green: questionok, red: questionwrong }"
              class="container encpanel"
            >
              <div class="row">
                <div class="field encryptionLetter" hidden>
                  <input class="input" value="" disabled />
                </div>
                <div
                  v-for="(answer, key, index) in $parent.language[this.gameName]
                    .answers"
                  class="field encryptionLetter"
                >
                  <input
                    class="input encBorder"
                    idl="demo"
                    style="text-transform: uppercase"
                    placeholder="?"
                    maxlength="1"
                    v-model="gameAnswers[key]"
                    :id="key"
                  />
                </div>
                <!-- v-on:keyup="this.$refs.secondbox.focus(); this.$refs.secondbox.select(); makeUpper(0);" -->

                <div class="">
                  <button
                    class="button"
                    type="submit"
                    style="border-color: #f29412; border-style: solid"
                    :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkcode()"
                  >
                    {{ $parent.language[this.gameName].check }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import { callApi } from "../plugins/callApi.js";

export default {
  name: "encryptionGame",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  async created() {
    console.log("created");
    console.log(this.gameAnswers);
    await this.populateClue();
    console.log(this.gameAnswers);
  },
  async mounted() {
    this.$emit("pauseTimer", 0);
    this.numberofAnswers = Object.keys(
      this.$parent.language[this.gameName].answers
    ).length;

    document.querySelectorAll("input").forEach((el) => {
      el.addEventListener("keydown", (e) => {
        switch (e.code) {
          case "Backspace":
            if (this.numberofAnswers > parseInt(el.id) + 1) {
              if (
                document.getElementById(parseInt(el.id) + 1).value.length == 0
              ) {
                if (parseInt(el.id) - 1 > -1) {
                  var nextbox = document
                    .getElementById(parseInt(el.id) - 1)
                    .focus();
                }
              }
            } else {
              if (document.getElementById(parseInt(el.id)).value.length == 0) {
                var nextbox = document
                  .getElementById(parseInt(el.id - 1))
                  .focus();
              }
            }
            break;
          default:
            document.getElementById(parseInt(el.id)).value = "";
            this.gameAnswers[el.id] = "";
            break;
        }
      });

      el.addEventListener("keyup", (e) => {
        switch (e.code) {
          //   case "KeyA": // fallthrough
          //   case "KeyB": // fallthrough
          //   case "KeyC": // fallthrough
          //   case "KeyD": // fallthrough
          //   case "KeyE": // fallthrough
          //   case "KeyF": // fallthrough
          //   case "KeyG": // fallthrough
          //   case "KeyH": // fallthrough
          //   case "KeyI": // fallthrough
          //   case "KeyJ": // fallthrough
          //   case "KeyK": // fallthrough
          //   case "KeyL": // fallthrough
          //   case "KeyM": // fallthrough
          //   case "KeyN": // fallthrough
          //   case "KeyO": // fallthrough
          //   case "KeyP": // fallthrough
          //   case "KeyQ": // fallthrough
          //   case "KeyR": // fallthrough
          //   case "KeyS": // fallthrough
          //   case "KeyT": // fallthrough
          //   case "KeyU": // fallthrough
          //   case "KeyV": // fallthrough
          //   case "KeyW": // fallthrough
          //   case "KeyX": // fallthrough
          //   case "KeyY": // fallthrough
          //   case "KeyZ":
          case "Digit0":
          case "Digit1":
          case "Digit2":
          case "Digit3":
          case "Digit4":
          case "Digit5":
          case "Digit6":
          case "Digit7":
          case "Digit8":
          case "Digit9":
          case "Numpad0":
          case "Numpad1":
          case "Numpad2":
          case "Numpad3":
          case "Numpad4":
          case "Numpad5":
          case "Numpad6":
          case "Numpad7":
          case "Numpad8":
          case "Numpad9":
            if (this.numberofAnswers > parseInt(el.id) + 1) {
              var nextbox = document
                .getElementById(parseInt(el.id) + 1)
                .focus();
            }
            break;
          default:
            console.log("default");
            this.gameAnswers[el.id] = "";
            document.getElementById(parseInt(el.id)).value = "";
            break;
        }
      });
    });
    // if (this.$parent.language[this.gameName].clue0color != "") {
    //   this.$refs.firstbox.style.color = this.$parent.language[this.gameName].clue0Color;
    //   this.$refs.firstbox.style.borderColor = this.$parent.language[this.gameName].clue0Color;
    // }

    // if (this.$parent.language[this.gameName].clue1color != "") {
    //   this.$refs.secondbox.style.color = this.$parent.language[this.gameName].clue1Color;
    //   this.$refs.secondbox.style.borderColor = this.$parent.language[this.gameName].clue1Color;
    // }

    // if (this.$parent.language[this.gameName].clue2color != "") {
    //   this.$refs.thirdbox.style.color = this.$parent.language[this.gameName].clue2Color;
    //   this.$refs.thirdbox.style.borderColor = this.$parent.language[this.gameName].clue2Color;
    // }

    // if (this.$parent.language[this.gameName].clue3color != "") {
    //   this.$refs.fourthbox.style.color = this.$parent.language[this.gameName].clue3Color;
    //   this.$refs.fourthbox.style.borderColor = this.$parent.language[this.gameName].clue3Color;
    // }

    // if (this.$parent.language[this.gameName].clue5color != "") {
    //   this.$refs.fithbox.style.color = this.$parent.language[this.gameName].clue4Color;
    //   this.$refs.fithbox.style.borderColor = this.$parent.language[this.gameName].clue4Color;
    // }

    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;
    //   axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": "encryptionStart", "dbid": this.dbid })
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName,
      dbid: this.dbid,
    });
    this.$emit("pauseTimer", 0);

    // this.$refs.firstbox.focus()
    //  axios
    //  .post('http://192.168.1.224:3000/updatestage', {"stage": 7})
    //  setTimeout(() => {  this.GameMove() }, 120000);

    while (
      this.randNumber.length !=
      this.$parent.gameSettings.encryptionVisibleLetters
    ) {
      console.log("number");
      var randomnumber = Math.ceil(Math.random() * 5);
      if (this.randNumber.indexOf(randomnumber) === -1) {
        this.randNumber.push(randomnumber);
      }
    }
  },

  data() {
    return {
      letter1: "",
      letter2: "",
      letter3: "",
      letter4: "",
      letter5: "",
      letter6: "",
      questionok: false,
      questionwrong: false,
      randNumber: [],
      gameAnswers: [],
      numberofAnswers: 0,
    };
  },
  methods: {
    // makeUpper(number) {
    //   if (number == 0) {
    //     this.letter1 = this.letter1.toUpperCase()
    //   }
    //   if (number == 1) {
    //     this.letter2 = this.letter2.toUpperCase()
    //   }
    //   if (number == 2) {
    //     this.letter3 = this.letter3.toUpperCase()
    //   }
    //   if (number == 3) {
    //     this.letter4 = this.letter4.toUpperCase()
    //   }
    //   if (this.$parent.language[this.gameName].clue4 != '') {
    //     if (number == 4) {
    //       this.letter5 = this.letter5.toUpperCase()
    //     }
    //   }
    // },
    async checkcode() {
      var correct = 0;
      for (var i = 0; i < this.numberofAnswers; i++) {
        console.log(
          this.$parent.language[this.gameName]["clue" + i],
          document.getElementById(i).value
        );
        if (
          this.$parent.language[this.gameName]["clue" + i] ==
          document.getElementById(i).value
        ) {
          correct++;
        }
      }

      if (this.numberofAnswers == correct) {
        console.log("pass");
        this.questionwrong = false;
        this.questionok = true;
        var setup = callApi("updateprogress", this.license, {
          gameName: this.gameName + "End",
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      } else {
        console.log("error");
        var setup = callApi("incorrectattempt", this.license, {
          gameName: this.gameName,
          dbid: this.dbid,
        });
        this.questionwrong = true;
      }
    },
    populateClue() {
      if ("prepopulate" in this.$parent.language[this.gameName]) {
        console.log("has array");
        console.log(this.$parent.language[this.gameName]["prepopulate"]);
        for (
          var i = 0;
          i < this.$parent.language[this.gameName]["prepopulate"].length;
          i++
        ) {
          console.log(i);
          if (this.$parent.language[this.gameName]["prepopulate"][i] != "") {
            this.gameAnswers.push(
              this.$parent.language[this.gameName]["prepopulate"][
                i
              ].toUpperCase()
            );
          } else {
            this.gameAnswers.push("");
          }
        }

        // for (var number of this.randNumber) {
        //   switch (number) {
        //     case 1:
        //       this.gameAnswers[0] =
        //         this.$parent.language[this.gameName].clue0.toUpperCase();
        //       break;
        //     case 2:
        //       this.gameAnswers[1] =
        //         this.$parent.language[this.gameName].clue1.toUpperCase();
        //       break;
        //     case 3:
        //       this.gameAnswers[2] =
        //         this.$parent.language[this.gameName].clue2.toUpperCase();
        //       break;
        //     case 4:
        //       this.gameAnswers[3] =
        //         this.$parent.language[this.gameName].clue3.toUpperCase();
        //       break;
        //     case 5:
        //       this.gameAnswers[4] =
        //         this.$parent.language[this.gameName].clue4.toUpperCase();
        //       break;
        // }
      }
    },
  },
};
</script>



