<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe" style="padding-left: 20px;">
            <div v-html="$parent.language.endScreen.feedbackbody"></div>

            <div class="field field--button btnwidth">
              <button class="button" type="submit" @click="openFeedback()">{{ $parent.language.endScreen.feedback
              }}</button>
            </div>

            <div class="field field--button btnwidth">
              <button class="button" type="submit" @click="endGame()">{{ $parent.language.endScreen.done }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
//  import axios from 'axios'
import { callApi } from '../plugins/callApi.js'
export default {
  name: 'endScreen',
  props: {
    msg: String,
    dbid: String
  },
  data() {
    return {
      teamName: ""
    }
  },
  async mounted() {
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    // axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": "End/"+this.$parent.timerValue, "dbid": this.dbid })
    var setup = await callApi("updateprogress", this.license, { "gameEnd": true, timer: this.$parent.timerValue, "gameName": "End", "dbid": this.dbid })
  },
  methods: {
    endGame() {
      this.$emit('reset', "reset")
    },
    openFeedback() {
      window.open(this.$parent.language.endScreen.url, '_blank').focus();
    }
  }
}
</script>


