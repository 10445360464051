<template>
  <div class="backgroundLogoCont">



       
  </div>
</template>
  
<style>
@import '../assets/form.css';
</style>
  
<script>

export default {
  name: 'CustomBackground',


  mounted() {
     document.body.style.backgroundImage = "url("+this.$parent.logoData[this.$parent.gameSettings["customBackground"]]+")";

  }
}
</script>

  