<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe extrapadding">
            <div v-html="$parent.language[this.gameName].body"></div>
            <div class="bigTimer">{{ formatedCountdown }}</div>
          </div>
          <div>
            <div
              v-bind:class="{ green: questionok, red: questionwrong }"
              class="container encpanel"
            >
              <div class="row">
                <div class="field encryptionLetter" hidden>
                  <input class="input" value="" disabled />
                </div>
                <div class="field encryptionLetter">
                  <input
                    class="input encBorder"
                    style="text-transform: uppercase"
                    placeholder="?"
                    maxlength="1"
                    v-model="letter1"
                    ref="firstbox"
                    v-on:keyup="
                      this.$refs.secondbox.focus();
                      this.$refs.secondbox.select();
                      makeUpper(0);
                      this.populateClue();
                    "
                  />
                </div>
                <div class="field encryptionLetter">
                  <input
                    class="input encBorder"
                    style="text-transform: uppercase"
                    placeholder="?"
                    maxlength="1"
                    v-model="letter2"
                    ref="secondbox"
                    v-on:keyup="
                      this.$refs.thirdbox.focus();
                      this.$refs.thirdbox.select();
                      makeUpper(1);
                      this.populateClue();
                    "
                  />
                </div>
                <div class="field encryptionLetter">
                  <input
                    class="input encBorder"
                    style="text-transform: uppercase"
                    placeholder="?"
                    maxlength="1"
                    v-model="letter3"
                    ref="thirdbox"
                    v-on:keyup="
                      this.$refs.fourthbox.focus();
                      this.$refs.fourthbox.select();
                      makeUpper(2);
                      this.populateClue();
                    "
                  />
                </div>
                <div class="field encryptionLetter">
                  <input
                    class="input encBorder"
                    style="text-transform: uppercase"
                    placeholder="?"
                    maxlength="1"
                    v-model="letter4"
                    ref="fourthbox"
                    v-on:keyup="
                      this.$refs.fithbox.focus();
                      this.$refs.fithbox.select();
                      makeUpper(3);
                      this.populateClue();
                    "
                  />
                </div>
                <div
                  class="field encryptionLetter"
                  v-if="this.$parent.language[this.gameName].clue4 != ''"
                >
                  <input
                    class="input encBorder"
                    style="text-transform: uppercase"
                    placeholder="?"
                    maxlength="1"
                    v-model="letter5"
                    ref="fithbox"
                    v-on:blur="makeUpper(4)"
                    v-on:keyup="
                      this.$refs.firstbox.focus();
                      this.$refs.firstbox.select();
                      makeUpper(4);
                      this.populateClue();
                    "
                  />
                </div>

                <div class="">
                  <button
                    class="button"
                    type="submit"
                    style="border-color: #f29412; border-style: solid"
                    :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkcode()"
                  >
                    {{ $parent.language[this.gameName].check }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../assets/form.css";

.bigTimer {
  font-family: twenty !important;
  font-size: 70px;
  color: #d7d335;
}
</style>

<script>
import { callApi } from "../plugins/callApi.js";
import Timer from "./timerrunning.vue";
import * as moment from "moment";
export default {
  name: "encryptionCountdown",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  components: {
    Timer,
  },
  async mounted() {
    var setup = callApi("updateprogress", this.license, {
      gameName: "TimerStart",
      dbid: this.dbid,
    });
    this.countdown = this.$parent.gameSettings.timer;
    setInterval(() => {
      if (this.timerDone == false) {
        this.countdown -= 1;
      }
      this.$emit("updateTimer", this.countdown);

      if (this.penalty === true) {
        this.countdown -= 30;
        this.$emit("penaltyDone", "");
      }

      if (this.countdown == 0) {
        console.log("count down done");
        this.timerDone = true;

        // clearInterval(stopCountdown)
      }
    }, 1000);

    this.$emit("startTimer", 1);

    if (this.$parent.gameSettings.customBackground != "") {
    }

    if (this.$parent.language[this.gameName].clue0color != "") {
      this.$refs.firstbox.style.color =
        this.$parent.language[this.gameName].clue0Color;
      this.$refs.firstbox.style.borderColor =
        this.$parent.language[this.gameName].clue0Color;
    }

    if (this.$parent.language[this.gameName].clue1color != "") {
      this.$refs.secondbox.style.color =
        this.$parent.language[this.gameName].clue1Color;
      this.$refs.secondbox.style.borderColor =
        this.$parent.language[this.gameName].clue1Color;
    }

    if (this.$parent.language[this.gameName].clue2color != "") {
      this.$refs.thirdbox.style.color =
        this.$parent.language[this.gameName].clue2Color;
      this.$refs.thirdbox.style.borderColor =
        this.$parent.language[this.gameName].clue2Color;
    }

    if (this.$parent.language[this.gameName].clue3color != "") {
      this.$refs.fourthbox.style.color =
        this.$parent.language[this.gameName].clue3Color;
      this.$refs.fourthbox.style.borderColor =
        this.$parent.language[this.gameName].clue3Color;
    }

    if (this.$parent.language[this.gameName].clue5color != "") {
      this.$refs.fithbox.style.color =
        this.$parent.language[this.gameName].clue4Color;
      this.$refs.fithbox.style.borderColor =
        this.$parent.language[this.gameName].clue4Color;
    }

    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;
    //   axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": "encryptionStart", "dbid": this.dbid })
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName,
      dbid: this.dbid,
    });

    this.$emit("pauseTimer", 0);

    this.$refs.firstbox.focus();
    //  axios
    //  .post('http://192.168.1.224:3000/updatestage', {"stage": 7})
    //  setTimeout(() => {  this.GameMove() }, 120000);

    while (
      this.randNumber.length !=
      this.$parent.gameSettings.encryptionVisibleLetters
    ) {
      console.log("number");
      var randomnumber = Math.ceil(Math.random() * 5);
      if (this.randNumber.indexOf(randomnumber) === -1) {
        this.randNumber.push(randomnumber);
      }

      this.populateClue();
    }
  },
  data() {
    return {
      letter1: "",
      letter2: "",
      letter3: "",
      letter4: "",
      letter5: "",
      letter6: "",
      questionok: false,
      questionwrong: false,
      randNumber: [],
      pauseTimer: 0,
      countdown: 0,
      timerDone: false,
    };
  },
  computed: {
    formatedCountdown() {
      return moment
        .duration(this.countdown, "seconds")
        .format("mm:ss", { trim: false });
    },
  },
  methods: {
    makeUpper(number) {
      if (number == 0) {
        this.letter1 = this.letter1.toUpperCase();
      }
      if (number == 1) {
        this.letter2 = this.letter2.toUpperCase();
      }
      if (number == 2) {
        this.letter3 = this.letter3.toUpperCase();
      }
      if (number == 3) {
        this.letter4 = this.letter4.toUpperCase();
      }
      if (this.$parent.language[this.gameName].clue4 != "") {
        if (number == 4) {
          this.letter5 = this.letter5.toUpperCase();
        }
      }
    },
    async checkcode() {
      var userclue =
        this.letter1.toLowerCase() +
        this.letter2.toLowerCase() +
        this.letter3.toLowerCase() +
        this.letter4.toLowerCase() +
        this.letter5.toLowerCase();

      if (userclue == this.$parent.language[this.gameName].answer) {
        console.log("pass");
        this.questionwrong = false;
        this.questionok = true;
        var setup = callApi("updateprogress", this.license, {
          gameName: this.gameName + "End",
          dbid: this.dbid,
        });
        var setup = callApi("updateprogress", this.license, {
          gameName: "TimerStop",
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      } else {
        console.log("error");
        var setup = await callApi("incorrectattempt", this.license, {
          gameName: this.gameName,
          dbid: this.dbid,
        });
        this.questionwrong = true;
      }
    },
    populateClue() {
      for (var number of this.randNumber) {
        switch (number) {
          case 1:
            this.letter1 =
              this.$parent.language[this.gameName].clue0.toUpperCase();
            break;
          case 2:
            this.letter2 =
              this.$parent.language[this.gameName].clue1.toUpperCase();
            break;
          case 3:
            this.letter3 =
              this.$parent.language[this.gameName].clue2.toUpperCase();
            break;
          case 4:
            this.letter4 =
              this.$parent.language[this.gameName].clue3.toUpperCase();
            break;
          case 5:
            this.letter5 =
              this.$parent.language[this.gameName].clue4.toUpperCase();
            break;
        }
      }
    },
  },
};
</script>



