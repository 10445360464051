
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="$parent.language.password.body"></div>

            <div class="question" v-bind:class="{ green: question1ok, red: question1wrong }"> 
              <div class="row btnwidth rowlabel">
                <div v-html="$parent.language.password.newPassword"></div>
              </div>
              <div class="row btnwidth rowlabel socialquestion">

                <div class="field">
                  <input class="input" placeholder="" v-model="newPassword" @keyup="checkPassword" />
                </div>
                <div class="field" id="passwordStrength">
                  <input class="input" placeholder="" v-model="passwordMessage" style="text-align:center;" :style="{'background-color':passwordColor}" />
                </div>
         
              </div>
              <div class=" ">
              <div class="fieldSingleBtn ">
                <button class="button" id="subBtn" type="submit" :style="{'background-color':$parent.buttonColor}" @click="savePassword()" >{{$parent.language.password.savePassword}}</button>
              </div>
            </div>
          </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
 import axios from 'axios'

 export default {
  name: 'StartGame',
  props: {
    msg: String,
     dbid: String
  },  
  async mounted () {

    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor 

    document.querySelector('#subBtn').disabled = true
    document.querySelector('#passwordStrength').className = "fieldRed"
    document.querySelector('#subBtn').className = "buttonDisabled"
    axios.post(process.env.VUE_APP_ip+'/updateprogress', {"gameName": "passwordStart", "dbid": this.dbid})
    
   
    this.$emit('pauseTimer', 0)
  },
  data () {
   return {
    newPassword: "",
    passwordColor: "red",
    passwordMessage: "Weak"
  }
},
methods: {
  checkPassword(){

    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    // .field:first-child:after
    if(strongPassword.test(this.newPassword)) {
            this.passwordColor = "green"
            this.passwordMessage = 'Strong'
            document.querySelector('#passwordStrength').className = "fieldGreen"
            document.querySelector('#subBtn').className = "button"
            document.querySelector('#subBtn').disabled = false
        } else if(mediumPassword.test(this.newPassword)){
          this.passwordColor = 'blue'
          this.passwordMessage = 'Medium'
          document.querySelector('#passwordStrength').className = "fieldBlue"
          document.querySelector('#subBtn').className = "buttonDisabled"
          document.querySelector('#subBtn').disabled = true
        } else{
          this.passwordColor = 'red'
          this.passwordMessage = 'Weak'
          document.querySelector('#passwordStrength').className = "fieldRed"
          document.querySelector('#subBtn').className = "buttonDisabled"
          document.querySelector('#subBtn').disabled = true
          

        }

  },
 async savePassword(){
    await axios.post(process.env.VUE_APP_ip+'/updateprogress', {"gameName": "passwordEnd", "dbid": this.dbid})
       this.$emit('updatestage', "")
  }
  // checkQuestion(number){
  //   if (number == 1 ){
  //     this.question1ok = ""
  //     this.question1wrong = ""
  //     if (this.question1.toLowerCase() == this.$parent.language.socialQuestions.answer1){
  //       this.question1ok = true
  //     } else {
  //       this.question1wrong = true
  //     }
  //   }

  //   if (number == 2 ){
  //     this.question2ok = ""
  //     this.question2wrong = ""
  //     if (this.question2.toLowerCase() == this.$parent.language.socialQuestions.answer2 || this.question2.toLowerCase() == this.$parent.language.socialQuestions.answer21){
  //       this.question2ok = true
  //     } else {
  //       this.question2wrong = true
  //     }
  //   }

  //   if (number == 3 ){
  //     this.question3ok = ""
  //     this.question3wrong = ""
  //     if (this.question3.toLowerCase() == this.$parent.language.socialQuestions.answer3 || this.question3.toLowerCase() == this.$parent.language.socialQuestions.answer31 || this.question3.toLowerCase() == this.$parent.language.socialQuestions.answer33){
  //       this.question3ok = true
  //     } else {
  //       this.question3wrong = true
  //     }
  //   }
  //   if (number == 4 ){
  //     this.question4ok = ""
  //     this.question4wrong = ""
  //     console.log(this.question4)
  //     if (this.question4 == this.$parent.language.socialQuestions.answer4){
  //       this.question4ok = true
  //     } else {
  //       this.question4wrong = true
  //     }
  //   }

  //   if (this.question1ok == true && this.question2ok == true && this.question3ok == true && this.question4ok == true){
  //     axios
  //   .post(process.env.VUE_APP_ip+'/updatestage', {"stage": 9, "dbid": this.dbid})
  //   }

  // },
}
}
</script>


