<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <div v-html="text"></div>

            <div class="field field--button btnwidth">
              <button
                class="button"
                type="submit"
                :style="{ 'background-color': $parent.buttonColor }"
                @click="StartGame()"
              >
                {{ this.button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import axios from "axios";

export default {
  name: "TextView",
  props: {
    msg: String,
    dbid: String,
    text: String,
    button: String,
  },
  data() {
    return {
      teamName: "",
    };
  },
  mounted() {
    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;
    this.$emit("pauseTimer", 0);
    if (this.$parent.stage == this.$parent.gameSettings.timerStartStage) {
      this.$emit("startTimer", 1);
      this.$emit("pauseTimer", 1);
    }
  },
  methods: {
    StartGame() {
      this.$emit("updatestage", "");
    },
     keyDownHandler(e) {
     
        console.log(e.key)
        // Your handler code here
      switch(e.key) {
  case "[":
    console.log("start")
    break;
  case "]":
    console.log("stage1")
    break;
  case "=":
    console.log("failed")
    break;
  default:
    key = ""
}
    },
  
    
  },
   created() {
    window.addEventListener('keydown', this.keyDownHandler)
},
destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler)
},
};
</script>


