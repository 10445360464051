
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="gameData.body" class="headdingPadding"></div>
            <div class="questionRow">
              <div class="question">
                <div class="row btnwidth rowlabel">
                  <div v-html="gameData.question1"></div>
                </div>
                <div class="row btnwidth rowlabel socialquestion">

                  <div class="field">
                    <input class="input" placeholder="" v-model="question1"
                      v-bind:class="{ greenAnswer: question1ok, redAnswer: question1wrong }" />
                  </div>
                  <div class="field field--button ">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(1)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="gameData.question2 != ''">
              <div class="questionRow">
                <div class="question">
                  <div class="row btnwidth rowlabel">
                    <div v-html="gameData.question2"></div>
                  </div>
                  <div class="row btnwidth rowlabel socialquestion">

                    <div class="field">
                      <input class="input" placeholder="" v-model="question2"
                        v-bind:class="{ greenAnswer: question2ok, redAnswer: question2wrong }" />
                    </div>
                    <div class="field field--button">
                      <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                        @click="checkQuestion(2)">{{ gameData.check }}</button>
                    </div>
                  </div>
                </div>
              </div>

            </template>
          </div>
          <template v-if="gameData.question3 != ''">
            <div class="questionRow">
              <div class="question">
                <div class="row btnwidth rowlabel socialquestion">
                  <div v-html="gameData.question3"></div>
                </div>
                <div class="row btnwidth rowlabel">

                  <div class="field">
                    <input class="input" placeholder="" v-model="question3"
                      v-bind:class="{ greenAnswer: question3ok, redAnswer: question3wrong }" />
                  </div>
                  <div class="field field--button">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(3)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="gameData.question4 != ''">
            <div class="questionRow">
              <div class="question" v-bind:class="{ greenAnswer: question4ok, redAnswer: question4wrong }">
                <div class="row btnwidth rowlabel">
                  <div v-html="gameData.question4"></div>
                </div>
                <div class="row btnwidth rowlabel">

                  <div class="field">
                    <input type="input" class="input" placeholder="" v-model="question4" />
                  </div>
                  <div class="field field--button">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(4)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import { callApi } from '../plugins/callApi.js'

export default {
  name: 'Questions',
  props: {
    msg: String,
    dbid: String,
    gameName: String
  },
  async mounted() {
    console.log(this.gameName)
    console.log(this.dbid)
    this.gameData = this.$parent.language[this.gameName]
    var setup = await callApi("gamesetup", this.license, { "gameName": this.gameName + "Start", "dbid": this.dbid })
    //  axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    this.$emit('pauseTimer', 0)
  },
  data() {
    return {
      gameData: [],
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question1ok: "",
      question1wrong: "",
      question2ok: "",
      question2wrong: "",
      question3ok: "",
      question3wrong: "",
      question4ok: "",
      question4wrong: ""
    }
  },
  methods: {
    checkQuestion(number) {
      if (number == 1) {
        this.question1ok = ""
        this.question1wrong = ""
        console.log()
        if (this.question1.toLowerCase() == this.gameData.answer1) {
          this.question1ok = true
        } else {
          this.question1wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question1", "dbid": this.dbid })
        }
      }

      if (number == 2) {
        this.question2ok = ""
        this.question2wrong = ""
        if (this.question2.toLowerCase() == this.gameData.answer2 || this.question2.toLowerCase() == this.gameData.answer21) {
          this.question2ok = true
        } else {
          this.question2wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question2", "dbid": this.dbid })
        }
      }

      if (number == 3) {
        this.question3ok = ""
        this.question3wrong = ""
        if (this.question3.toLowerCase() == this.gameData.answer3 || this.question3.toLowerCase() == this.gameData.answer31 || this.question3.toLowerCase() == this.gameData.answer33) {
          this.question3ok = true
        } else {
          this.question3wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question3", "dbid": this.dbid })
        }
      }
      if (number == 4) {
        this.question4ok = ""
        this.question4wrong = ""
        console.log(this.question4)
        if (this.question4 == this.gameData.answer4) {
          this.question4ok = true
        } else {
          this.question4wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question4", "dbid": this.dbid })
        }
      }

      if (this.gameData.question3 == '') {
        this.question3ok = true
      }

      if (this.gameData.question4 == '') {
        this.question4ok = true
      }


      if (this.question1ok == true && this.question2ok == true && this.question3ok == true && this.question4ok == true) {
        axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "End", "dbid": this.dbid })
        this.$emit('updatestage', "")
      } else {
        axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "End", "dbid": this.dbid })
      }

    },
  }
}
</script>


