<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <div v-html="text"></div>
            <table class="table leadertbl table-bordered">
              <thead>
                <th width="50px">#</th>
                <th>Team Name</th>
                <th>Time (mm:ss:mss)</th>
              </thead>
              <tbody>
                <template v-for="(game, index) in tbldata" :key="game.time">
                  <tr>
                    <td>{{ Number(index) + 1 }}</td>
                    <td>{{ game.name }}</td>
                    <td>{{ game.displayTime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

            <div class="field field--button btnwidth">
              <button
                class="button"
                type="submit"
                :style="{ 'background-color': $parent.buttonColor }"
                @click="StartGame()"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import { callApi } from "../plugins/callApi.js";
const moment = require("moment");
export default {
  name: "TextView",
  props: {
    msg: String,
    dbid: String,
    text: String,
    button: String,
  },
  data() {
    return {
      teamName: "",
      tbldata: [],
    };
  },
  async mounted() {
    var lic = await localStorage.getItem("datadash-license");
    var scores = [];
    var leaderboardData = await callApi("leaderboard", lic, {
      gameTitle: this.gameName,
      dbid: this.dbid,
    });
    console.log(leaderboardData);
    for (var score of leaderboardData) {
      console.log(score.name);
      var time = score.TimerStop - score.TimerStart;
      var displaytime = moment(time).format("mm:ss:SSS");
      //var displaytime = this.diffInSeconds(score.TimerStart, score.TimerStop)
      scores.push({ name: score.name, displayTime: displaytime, time: time });
    }

    // Convert object to array
    const dataArray = Object.values(scores);

    // Sort the array by the 'time' property
    dataArray.sort((a, b) => a.time - b.time);

    // Convert array back to object if necessary
    const sortedData = {};
    dataArray.forEach((item, index) => {
      if (index < 6) {
        sortedData[index] = item;
      }
    });
    this.tbldata = sortedData;
    // Output the sorted data
    console.log(sortedData);
  },
  methods: {
    StartGame() {
      // this.$emit('updatestage', "")
      window.location.replace("https://demo.xcapismlearning.com/");
    },
    diffInSeconds(timestampA, timestampB) {
      //  absolute value added incase you just want the diff but don't care which came first
      return Math.abs(timestampB - timestampA) / 1000;
    },
  },
};
</script>

