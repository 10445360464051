<template>
  <img alt="Codfather Logo" src="../assets/mainLogo.png" height="350" style="margin-top: 40px;">
  <div class="center">
    <p v-bind:class="{ isred: nameError }"> We might send you a text message during the game, please enter your mobile number below. </p>

    <div class="container">
      <div class="row">
        <input class="input" placeholder="Mobile Number" v-model="number" type="number" />
      </div>
      <div class="row">
        <button class="button" type="submit" @click="saveNumber()">Save</button>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'
export default {
  name: 'setupGame',
  props: {

  },
  data() {
    return {
      number: "",

    }
  },
  async mounted() {

  },
  methods: {
    async saveNumber() {
     console.log(this.number)
      this.$emit('mobile', this.number)
  
   
    }
  }
}
</script>


