<template :style="dynamicStyle">
  <template v-if="stage == 'agentCode'">
    <setup @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" />
  </template>

  <template v-if="stage == 'StartGameDemo'">
    <StartGameDemo @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @gameStages="setStages" @updatestage="updateStage" :dbid="this.dbid" />
  </template>


  <template v-if="stage == 'mobileNumber'">
    <mobileNumber @dbidfu="dbidupdate" @updatestage="updateStage" @mobile="SavemobileNumber" />
  </template>

  <template v-if="stage == 'languageChoice'">
    <language @dbidfu="dbidupdate" @langChoise="LanguageChoice" @updatestage="updateStage" />
  </template>



  <template v-if="stage == 'endScreen'">
    <endScreen @reset="endGameReset" @updatestage="updateStage" :dbid="this.dbid" />
  </template>



  <template v-if="stage == 'demo1StartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo1StartVideo" />
  </template>

  <template v-if="stage == 'demo1PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo1PlayScreen.body" :button="this.language.demo1PlayScreen.button" />
  </template>

  <template v-if="stage == 'demo1Code'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="demo1Code"
      @startTimer="enableTimer" />
  </template>


  <template v-if="stage == 'demo1EndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo1EndVideo" @pauseTimer="manageTimer" />
  </template>

  <template v-if="stage == 'demo2StartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo2StartVideo" />
  </template>

  <template v-if="stage == 'demo2PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo2PlayScreen.body" :button="this.language.demo2PlayScreen.button" />
  </template>

  <template v-if="stage == 'demo2Code'">
    <encryption @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      gameName="demo2Code" />
  </template>

  <template v-if="stage == 'demo2EndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo2EndVideo" />
  </template>


  <template v-if="stage == 'demo3StartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo3StartVideo" />
  </template>

  <template v-if="stage == 'demo3PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo3PlayScreen.body" :button="this.language.demo3PlayScreen.button" />
  </template>


  <template v-if="stage == 'demo3Code'">
    <socialQuestions :dbid="this.dbid" @pauseTimer="manageTimer" @startTimer="enableTimer" @updatestage="updateStage" />
  </template>

  <template v-if="stage == 'demo3EndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo3EndVideo" />
  </template>


  <!-- Glitch -->

  <template v-if="stage == 'StartGame'">
    <StartGame @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @gameStages="setStages" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

  <template v-if="stage == 'glitchStartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="glitchStartVideo" />
  </template>

  <template v-if="stage == 'glitchPlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.glitchPlayScreen.body" :button="this.language.glitchPlayScreen.button" />
  </template>

  <template v-if="stage == 'glitchPlayScreen2'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.glitchPlayScreen2.body" :button="this.language.glitchPlayScreen2.button" />
  </template>

  <template v-if="stage == 'glitchQuestions'">
    <MultiQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @startTimer="enableTimer" @updatestage="updateStage"  gameName="glitchQuestions" />
  </template>

  <template v-if="stage == 'glitchPlayScreen3'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.glitchPlayScreen3.body" :button="this.language.glitchPlayScreen3.button" />
  </template>

<!-- Glitch end -->

<!-- Shield start -->


<template v-if="stage == 'shieldStartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="shieldStartVideo" />
  </template>

  <template v-if="stage == 'shieldPlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.shieldPlayScreen.body" :button="this.language.shieldPlayScreen.button" />
  </template>

  <template v-if="stage == 'shieldPlayScreen2'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.shieldPlayScreen2.body" :button="this.language.shieldPlayScreen2.button" />
  </template>

  <template v-if="stage == 'shieldQuestions'">
    <QuestionsTickChoice :dbid="this.dbid" @pauseTimer="manageTimer" @startTimer="enableTimer" @updatestage="updateStage"  gameName="shieldQuestions" />
  </template>

  <template v-if="stage == 'shieldPlayScreen3'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.shieldPlayScreen3.body" :button="this.language.shieldPlayScreen3.button" />
  </template>

<!-- Shield End -->

<!-- datadoom -->



<template v-if="stage == 'StartGameHubSpot'">
    <StartGameHubSpot @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @gameStages="setStages" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

  <template v-if="stage == 'datadoomStartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="datadoomStartVideo" />
  </template>



  <template v-if="stage == 'datadoomTeamName'">
    <StartGame @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @gameStages="setStages" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

  <template v-if="stage == 'datadoomPlayScreen1'">
    <encryptionCountdown :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="datadoomPlayScreen1"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'datadoomPlayScreen2'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.datadoomPlayScreen2.body" :button="this.language.datadoomPlayScreen2.button" />
  </template>


  <template v-if="stage == 'leaderboard'">
    <leaderboard @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer" />
  </template>

  
  
<!-- datadoom End -->

<!-- ecrc soc -->

 <template v-if="stage == 'StartGameECRC'">
    <StartGameECRC @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @gameStages="setStages" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

<template v-if="stage == 'ecrcSocialStartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="ecrcSocialStartVideo" />
  </template>

  <template v-if="stage == 'ecrcSocialEndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="ecrcSocialEndVideo" />
  </template>

    <template v-if="stage == 'ecrcEncStartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="ecrcEncStartVideo" />
  </template>

    <template v-if="stage == 'ecrcEncEndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="ecrcEncEndVideo" />
  </template>

      <template v-if="stage == 'ecrcBrutStartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="ecrcBrutStartVideo" />
  </template>

    <template v-if="stage == 'ecrcBrutEndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="ecrcBrutEndVideo" />
  </template>

  <template v-if="stage == 'ecrcbrut'">
    <encryptionv2 @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      gameName="ecrcbrut" />
  </template>


<!-- ecrc soc -->

<!-- wiregame -->
    <template v-if="stage == 'wireGame'">
    <wireGame @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.wireGame.body" :button="this.language.wireGame.button" />
  </template>
<!-- wireGame end -->

  <!--  -->
  <template v-if="this.gameSettings.clockDisable == false">
  <template v-if="timer == 1 && this.gameSettings.clock != 'clock'">
    <Timer :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone" />

  </template>

  <template v-if="timer == 1 && this.gameSettings.clock == 'clock'">
    <timerClock :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone" />
  </template>
</template>

  <template v-if="stage != 'agentCode'">
    <cornerLogo @updatestage="updateStage" @previousstage="previousStage" />
  </template>

  <template v-if="stage != 'agentCode' && stage != 'mobileNumber'">
    <background />
  </template>
  <template v-if="gameSettings.customBackground != '' && stage != 'agentCode'">
    <custombackground />

    </template>

</template>

<script>





import axios from 'axios'
import cornerLogo from './components/cornerLogo.vue'
import setup from './components/setup.vue'
import StartGame from './components/StartGame.vue'
import TextView from './components/TextView.vue'
import TextView2 from './components/TextView2.vue'
import Timer from './components/timer.vue'
import hackerVideo from './components/hackerVideo.vue'
import bruteVideo from './components/bruteVideo.vue'
import bruteClue from './components/bruteClue.vue'
import bruteClueManual from './components/bruteClueManual.vue'
import encryption from './components/encryptionv2.vue'
import socialVideo from './components/socialVideo.vue'
import socialQuestions from './components/socialQuestions.vue'
import encryptionVideo from './components/encryptionVideo.vue'
import messagesVideo from './components/messagesVideo.vue'
import messagesGame from './components/messagesGame.vue'
import finalHacker from './components/finalHacker.vue'
import endGame from './components/endGame.vue'
import endScreen from './components/endScreen.vue'
import trainingVideo from './components/trainingVideo.vue'
import language from './components/language.vue'
const videos = require('./plugins/video.js')
const logo = require('./plugins/logo.js')
import passwordVideo from './components/passwordVideo.vue'
import password from './components/password.vue'
import explainerVideo from './components/explainerVideo.vue'
import TextViewdy from './components/TextViewdy.vue'
import Questions from './components/Questions.vue'
import MultiQuestion from './components/QuestionsMultiChoice.vue'
import timerClock from './components/timerClock.vue'
import SingleQuestion from './components/SingleQuestion.vue'
import SingleQuestionLong from './components/SingleQuestionLong.vue'
import videody from './components/videody.vue'
import midClue from './components/midClue.vue'
import QuestionsTickChoice from './components/QuestionsTickChoice.vue'
import mobileNumber from './components/mobileNumber.vue'
import background from './components/background.vue'
import StartGameDemo from './components/StartGameDemo.vue'
import StartGameHubSpot from './components/StartGameHubSpot.vue'
import encryptionCountdown from './components/encryptionCountdown.vue'
import custombackground from './components/custombackground.vue'
import leaderboard from './components/leaderboard.vue'
import wireGame from './components/wireGame.vue'
import StartGameECRC from './components/StartGameECRC.vue'
import encryptionv2 from './components/encryptionv2.vue'

export default {
  name: 'App',
  components: {
    passwordVideo,
    password,
    cornerLogo,
    StartGame,
    StartGameDemo,
    TextView,
    TextView2,
    hackerVideo,
    bruteVideo,
    Timer,
    encryption,
    socialVideo,
    socialQuestions,
    bruteClue,
    bruteClueManual,
    encryptionVideo,
    messagesVideo,
    messagesGame,
    finalHacker,
    endGame,
    endScreen,
    setup,
    language,
    trainingVideo,
    explainerVideo,
    TextViewdy,
    Questions,
    MultiQuestion,
    timerClock,
    SingleQuestion,
    videody,
    midClue,
    SingleQuestionLong,
    QuestionsTickChoice,
    mobileNumber,
    background,
    StartGameHubSpot,
    encryptionCountdown,
    custombackground,
    leaderboard,
    wireGame,
    StartGameECRC,
    encryptionv2
  },
  created() {
    document.title = "Xcapism Learning Mini Games";
  },
  data() {
    return {
      info: '',
      name: '',
      startsound: false,
      stage: "agentCode",
      timer: 0,
      pauseTimer: 0,
      timerValue: 0,
      penalty: false,
      penaltyCount: 0,
      dbid: "62816a01c0014f5adad845d5",
      language: '',
      langOptions: '',
      chosenLanguage: '',
      videoData: [],
      logoData: [],
      videoPath: 'XL',
      GlobalLanguageContentObj: {},
      gameSettings: {},
      gameBackground: "#172031",
      buttonColor: "#f29412",
      logoFile: "logo1.png",
      gameStages: [],
      mobileNumber: "",
      background: 1

    }
  },
  async mounted() {

    this.videoData = await videos.setup()
    this.logoData = await logo.setup()

  },
  methods: {
    endGameReset() {
      console.log("reset")
      this.info = ''
      this.name = ''
      this.startsound = false
      this.stage = "agentCode"
      this.timer = 0
      this.pauseTimer = 0
      this.timerValue = 0
      this.penalty = false
      this.penaltyCount = 0
      this.dbid = "62816a01c0014f5adad845d5"
    },
    penaltyDone() {
      this.penalty = false
      this.penaltyCount += 1
    },
    addPenalty() {
      this.penalty = true
    },
    enableTimer(value) {
      this.timer = value

    },
    updateTimer(value) {
      this.timerValue = value

    },
    getNotifications() {
      return axios
        .post(process.env.VUE_APP_ip + '/gamedata', { "dbid": this.dbid })
    },
    manageTimer(value) {
      this.pauseTimer = value;
    },
    dbidupdate(value) {
      this.dbid = value
    },
    setLanguage(value) {
      this.langOptions = value
    },
    setStages(value) {
      this.gameStages = value
    },
    SavemobileNumber(value) {
      console.log(value)
      this.mobileNumber = value
      this.updateStage()
    },
    updateStage(value) {
      var newIndex = (this.gameStages.indexOf((this.stage))) + 1
      this.stage = this.gameStages[newIndex]
    },
    previousStage(value) {
      var newIndex = (this.gameStages.indexOf((this.stage))) - 1
      this.stage = this.gameStages[newIndex]
    },
    LanguageChoice(value) {
      this.chosenLanguage = value
      this.language = this.GlobalLanguageContentObj[value]
    },
    VideoPath(value) {
      this.videoPath = value
    },
    GlobalLanguageContent(value) {
      this.GlobalLanguageContentObj = value
    },
    gameSetting(value) {
      this.gameSettings = value

      // here we can set any global chnages
      this.gameSettings.background = value.background
      document.body.style.backgroundColor = this.gameSettings.background;
      document.body.style.color = this.gameSettings.textColor;
      // document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.gameSettings.containerColor
      this.buttonColor = value.buttonColor
      this.logoFile = this.gameSettings.logoFile
      this.containerColor = this.gameSettings.containerColor


    },

  }
}
</script>

<style>
@import './assets//styles.css';


</style>



