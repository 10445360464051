<template>
  <img
    alt="Codfather Logo"
    :src="$parent.logoData[$parent.logoFile]"
    height="150"
    style="margin-top: 40px"
  />
  <div class="center">
    <p v-bind:class="{ isred: nameError }">
      {{ $parent.language.StartGame.body }}
    </p>

    <div class="containerNoBack" v-bind:class="{ green: clicked }">
      <div id="hubspotForm" v-once></div>

      <!-- <div class="row">
      <div class="field">
        <input class="input" :placeholder='$parent.language.StartGame.placeholder ' v-model="teamName" />
      </div>
      <div class="field field--button">
        <button class="button" type="submit" :style="{'background-color':$parent.buttonColor}" @click="SendTeamName()">{{$parent.language.StartGame.go}}</button>
      </div>
    </div> -->
    </div>
  </div>
</template>

<style scoped>
@import "../assets/form.css";

.containerNoBack {
  background-color: #ffffff !important;
  color: #fff !important;
}
.hbspt-form {
  all: none !important;
  background-color: #801616 !important;
  border: solid 1px #801616 !important;

  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

/*
.hs-input {
  width: 220px !important;
  background-color: #626262 !important;
}
.hs-firstname{
background: blue !important;
} */
/* .hs-submit{
font-size: 100px !important;
background-color: orange !important;
}
.hs-email{
background: red !important;
}
.hs-firstname{
background: blue !important;
}
.hs-lastname{
background: green !important;
}
.hs-company{
background: purple !important;
} */
</style>

<script>
import axios from "axios";
import { callApi } from "../plugins/callApi.js";
export default {
  name: "StartGame",
  props: {
    msg: String,
    dbid: String,
  },
  data() {
    return {
      teamName: "",
      nameError: false,
      clicked: false,
    };
  },
  mounted() {
    this.CheckSubmited();
    const pageData = this;

    const script = document.createElement("script");
    script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "7233367",
          formId: "8e4b3e38-1906-4012-9849-79d3d69e73de",
          // portalId: "144710447",
          // formId: "553d9df3-50d9-4b1e-be1f-049ba67f9f31",
          target: "#hubspotForm",
          onFormSubmitted: function ($form, pageData) {
            document.cookie = "submit=true";
            console.log("demo");
          },
        });
      }
    });
  },

  methods: {
    async SendTeamName() {
      if (this.teamName == "") {
        this.nameError = true;
        this.clicked = false;
      } else {
        this.nameError = false;
        this.clicked = true;
        var setup = await callApi("newgame", this.license, {
          name: this.name,
          dbid: this.dbid,
          chosenLanguage: this.$parent.chosenLanguage,
        });
        //    await axios.post(process.env.VUE_APP_ip+'/newgame', {"name": this.teamName, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage})
        this.$emit("updatestage", "");
      }
    },
    async getCookieValue(name) {
      const regex = new RegExp(`(^| )${name}=([^;]+)`);
      const match = document.cookie.match(regex);
      if (match) {
        return match[2];
      }
    },
    async CheckSubmited() {
      window.setInterval(async () => {
        if ((await this.getCookieValue("submit")) == "true") {
          document.cookie = "submit=false";
          this.$emit("updatestage", "");
        }
      }, 2000);
    },
  },
};
</script>


