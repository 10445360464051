
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame ">
          <div class="innerframe">
            <div style="font-size: 20px;" v-html="gameData.body"></div>

            <table style="margin-left: 30%;">
              <template v-for="question in this.questions">
                <div class="center">
                  <tr class="center">
                    <input type="checkbox" :id="{ question }" :value="{ question }" v-model="choices" /> {{ question }}
                  </tr>
                </div>
              </template>
            </table>
            <div class=" ">
              <p>{{ message }}</p>
              <button class="button " type="submit" :style="{ 'background-color': $parent.buttonColor }"
                @click="checkQuestion(1)">{{ gameData.button }}</button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'



export default {
  name: 'QuestionsMultiChoice',
  props: {
    msg: String,
    dbid: String,
    gameName: String,

  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName]

    for (var question in this.gameData.questions) {
      this.questions.push(this.gameData.questions[question].question)
      if (this.gameData.questions[question].correct === true) {
        this.answers.push(this.gameData.questions[question].question)
      }
    }

    axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    this.$emit('pauseTimer', 0)


  },
  data() {
    return {
      gameData: [],
      answers: [],
      questions: [],
      choices: [],
      chosen: [],
      correctCount: 0,
      message: "",

    }
  },
  methods: {
    checkQuestion() {

      this.chosen = []
      this.correctCount = 0;
      for (var choice of this.choices) {

        this.chosen.push(choice.question)

        if (Object.values(this.answers).includes(choice.question)) {
          this.correctCount = this.correctCount + 1
        }

      }
      if (this.chosen.length > this.correctCount) {
        this.message = "Some answers are correect, check again"
      } else {
        if (this.correctCount < this.answers.length) {
          this.message = "Check again more to find"
        } else {
          this.message = "Correct!"
          this.$emit('updatestage', "")
        }

      }
    },
  }
}
</script>


