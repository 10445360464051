<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe" >
           <div v-html="$parent.language.TextView2.body"></div>

      <div class="field field--button btnwidth">
        <button class="button" type="submit" :style="{'background-color':$parent.buttonColor}" @click="StartGame()">{{$parent.language.TextView.button}}</button>
      </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
   import axios from 'axios'
   
  export default {
    name: 'TextView',
    props: {
      msg: String,
      dbid: String
    },
    data () {
     return {
      teamName: ""
    }
  },
  mounted () {
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor 
  },
  methods: {
    StartGame() {
      this.$emit('updatestage', "")
    }
  }
}
</script>


