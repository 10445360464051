<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
             <div v-html="$parent.language.messagesGame.body"></div>


            <table class="messageTable">
              <tr><th>{{$parent.language.messagesGame.messageNo}}</th><th>{{$parent.language.messagesGame.YourChoice}}</th><th>{{$parent.language.messagesGame.CorrectChoice}}</th></tr>
              <tr><td>{{$parent.language.messagesGame.Message1}}</td>
                <template v-if="this.info.message1answer != null">

                  <template v-if="this.info.message1answer == 0">
                   <td>{{$parent.language.messagesGame.safe}}</td>
                 </template>
                 <template v-else>
                  <td>{{$parent.language.messagesGame.notsafe}}</td>
                </template>
                <td>{{$parent.language.messagesGame.notsafe}}</td>

              </template>
              <template v-else>
                <td>?</td>
                <td>?</td>
              </template>
            </tr>
            <tr><td>{{$parent.language.messagesGame.Message2}}</td>
              <template v-if="this.info.message2answer != null">

                <template v-if="this.info.message2answer == 0">
                  <td>{{$parent.language.messagesGame.safe}}</td>
                </template>
                <template v-else>
                  <td>{{$parent.language.messagesGame.notsafe}}</td>
                </template>
                <td>{{$parent.language.messagesGame.notsafe}}</td>

              </template>
              <template v-else>
                <td>?</td>
                <td>?</td>
              </template>
            </tr>

            <tr><td>{{$parent.language.messagesGame.Message3}}</td>
              <template v-if="this.info.message3answer != null">

                <template v-if="this.info.message3answer == 0">
                 <td>{{$parent.language.messagesGame.safe}}</td>
               </template>
               <template v-else>
                <td>{{$parent.language.messagesGame.notsafe}}</td>
              </template>
              <td>{{$parent.language.messagesGame.safe}}</td>

            </template>
            <template v-else>
              <td>?</td>
              <td>?</td>
            </template>
          </tr>

        </table>

      </div>
    </div>
  </div>
</div>
</div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
 import axios from 'axios'

 export default {
  name: 'StartGame',
  props: {
    info: Object,
    dbid: String
  },
  data () {
   return {
    teamName: "",
    done: false
  }
},
async mounted () {

    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor 

  this.$emit('pauseTimer', 0)

  axios.post(process.env.VUE_APP_ip+'/messagestart', {"dbid": this.dbid }) //start the message game

  window.setInterval( async() => {
    if ( this.info.message1answer != null && this.info.message2answer != null && this.info.message3answer != null ){

      if (this.done == false){
       axios.post(process.env.VUE_APP_ip+'/updatestage', {"stage": 11, "dbid": this.dbid});
       this.done = true
     }
     
   }
 }, 1000);
},
methods: {

}
}
</script>


