export async function setup() {
  const videoData = [];

  videoData.demo1 = [];
  videoData.demo1.English = [];
  videoData.demo1.English.demo1StartVideo = require("../assets/video/demo1/English/beginning.mp4");
  videoData.demo1.English.demo1StartVideoTime = 34000; // 28000;
  videoData.demo1.English.demo1StartVideoSubs = require("../assets/video/demo1/English/beginning.vtt");

  videoData.demo1.English.demo1EndVideo = require("../assets/video/demo1/English/gameEnd.mp4");
  videoData.demo1.English.demo1EndVideoTime = 26000; // 28000;
  videoData.demo1.English.demo1EndVideoSubs = require("../assets/video/demo1/English/gameEnd.vtt");

  videoData.demo2 = [];
  videoData.demo2.English = [];
  videoData.demo2.English.demo2StartVideo = require("../assets/video/demo2/English/demo2StartVideo.mp4");
  videoData.demo2.English.demo2StartVideoTime = 40000; // 28000;
  videoData.demo2.English.demo2StartVideoSubs = require("../assets/video/demo2/English/demo2StartVideo.vtt");

  videoData.demo2.English.demo2EndVideo = require("../assets/video/demo2/English/endGame.mp4");
  videoData.demo2.English.demo2EndVideoTime = 42000; // 28000;
  videoData.demo2.English.demo2EndVideoSubs = require("../assets/video/demo2/English/gameEnd.vtt");

  videoData.demo3 = [];
  videoData.demo3.English = [];
  videoData.demo3.English.demo3StartVideo = require("../assets/video/demo3/English/beginning.mp4");
  videoData.demo3.English.demo3StartVideoTime = 35000; // 28000;
  videoData.demo3.English.demo3StartVideoSubs = require("../assets/video/demo3/English/beginning.vtt");

  videoData.demo3.English.demo3EndVideo = require("../assets/video/demo3/English/gameEnd.mp4");
  videoData.demo3.English.demo3EndVideoTime = 29000; // 28000;
  videoData.demo3.English.demo3EndVideoSubs = require("../assets/video/demo3/English/gameEnd.vtt");

  videoData.glitch = [];
  videoData.glitch.English = [];
  videoData.glitch.English.glitchStartVideo = require("../assets/video/glitch/English/start.mp4");
  videoData.glitch.English.glitchStartVideoTime = 47000; // 28000;
  videoData.glitch.English.glitchStartVideoSubs = require("../assets/video/glitch/English/start.vtt");

  videoData.shield = [];
  videoData.shield.English = [];
  videoData.shield.English.shieldStartVideo = require("../assets/video/shield/English/start.mp4");
  videoData.shield.English.shieldStartVideoTime = 26000; // 28000;
  videoData.shield.English.shieldStartVideoSubs = require("../assets/video/shield/English/start.vtt");

  videoData.DigitalXRAID = [];
  videoData.DigitalXRAID.English = [];
  videoData.DigitalXRAID.English.datadoomStartVideo = require("../assets/video/DigitalXRAID/English/startVideo.mp4");
  videoData.DigitalXRAID.English.datadoomStartVideoTime = 27000;
  videoData.DigitalXRAID.English.datadoomStartVideoSubs = require("../assets/video/DigitalXRAID/English/startVideo.vtt");

  videoData.phish = [];
  videoData.phish.English = [];
  videoData.phish.English.datadoomStartVideo = require("../assets/video/phish/English/startVideo.mp4");
  videoData.phish.English.datadoomStartVideoTime = 25000;
  videoData.phish.English.datadoomStartVideoSubs = require("../assets/video/phish/English/startVideo.vtt");

  videoData.ecrc = [];
  videoData.ecrc.English = [];
  videoData.ecrc.English.ecrcSocialStartVideo = require("../assets/video/ecrc/English/ECRCSocialMediaOpening.mp4");
  videoData.ecrc.English.ecrcSocialStartVideoTime = 44000;
  videoData.ecrc.English.ecrcSocialStartVideoSubs = require("../assets/video/ecrc/English/ecrcSocopen.vtt");

  videoData.ecrc.English.ecrcSocialEndVideo = require("../assets/video/ecrc/English/ECRCSocialMediaEnd.mp4");
  videoData.ecrc.English.ecrcSocialEndVideoTime = 56000;
  videoData.ecrc.English.ecrcSocialEndVideoSubs = require("../assets/video/ecrc/English/ecrcSocend.vtt");

  videoData.ecrc.English.ecrcEncStartVideo = require("../assets/video/ecrc/English/ECRCJigsawOpening.mp4");
  videoData.ecrc.English.ecrcEncStartVideoTime = 32000;
  videoData.ecrc.English.ecrcEncStartVideoSubs = require("../assets/video/ecrc/English/ecrcEncStart.vtt");

   videoData.ecrc.English.ecrcEncEndVideo = require("../assets/video/ecrc/English/ECRCJigsawEnd.mp4");
  videoData.ecrc.English.ecrcEncEndVideoTime = 26000;
  videoData.ecrc.English.ecrcEncEndVideoSubs = require("../assets/video/ecrc/English/ecrcEncEnd.vtt");

    videoData.ecrc.English.ecrcBrutStartVideo = require("../assets/video/ecrc/English/BriefcaseOpening.mp4");
  videoData.ecrc.English.ecrcBrutStartVideoTime = 43000;
  videoData.ecrc.English.ecrcBrutStartVideoSubs = require("../assets/video/ecrc/English/ecrcBrutStart.vtt");

   videoData.ecrc.English.ecrcBrutEndVideo = require("../assets/video/ecrc/English/BriefcaseEnd.mp4");
  videoData.ecrc.English.ecrcBrutEndVideoTime = 51000;
  videoData.ecrc.English.ecrcBrutEndVideoSubs = require("../assets/video/ecrc/English/ecrcBrutEnd.vtt");

  return videoData;
}
