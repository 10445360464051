<template>
  <div class="center">
    <p> {{ $parent.language.StartGame.headding }} </p>
    <p v-bind:class="{ isred: nameError }"> {{ $parent.language.StartGame.body }} </p>

    <div class="container" v-bind:class="{ green: clicked, red: error }">
    
       
          <p>Your Team Name is:<p></p> {{name}}</p>
          <p>Select you region below:</p>
          <!-- <input class="input" placeholder="Name" v-model="name" /> -->
  
      <div class="row">
        <!-- <input class="input" placeholder="Work Mobile" v-model="mobile" /> -->
        <select class="select " v-model="mobile">
          <option value="Select">Select</option>
          <option value="Bedfordshire">Bedfordshire</option>
           <option value="Cambridgeshire">Cambridgeshire</option>
           <option value="Essex">Essex</option>
           <option value="Hertfordshire">Hertfordshire</option>
           <option value="Kent">Kent</option>
            <option value="Norfolk">Norfolk</option>
             <option value="Suffolk">Suffolk</option>
        </select>
      </div>
      <div class="row">
        <button class="button" type="submit" :disabled="isDisable()" :style="{ 'background-color': $parent.buttonColorm }"
          @click="SendTeamName()">{{ $parent.language.StartGame.go
          }}</button>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';


</style>

<script>

import { callApi } from '../plugins/callApi.js'
const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');

export default {
  name: 'StartGameDemo',
  props: {
    msg: String,
    dbid: String
  },
  data() {
    return {
      name: "",
      email: "",
      mobile: "Select",
      nameError: false,
      clicked: false,
      error: false
    }
  },
  async mounted() {

    this.name = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals] });

  },
  methods: {
  
    isDisable() {
      var dis = this.mobile == "Select";
      return false
      return dis
    },
    async SendTeamName() {
      if (this.mobile == "Select") {
        this.nameError = true;
        this.clicked = false;
        this.error = true
      } else {
        this.nameError = false;
        this.clicked = true;
        this.error = false
        var setup = await callApi("newgame", this.license, { "name": this.name, extra: { "name": this.name, "teamArea": this.mobile }, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage })
        //  await axios.post(process.env.VUE_APP_ip + '/newgame', { "name": this.name, "email": this.email, "mobile": this.mobile, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage })
        this.$emit('updatestage', "")
      }

    }
  }
}
</script>


