<template>
  <img alt="Vue logo" :src="$parent.logoData[$parent.logoFile]" height="350">
  <div class="center">
    <p v-bind:class="{ isred: nameError }">Please select a language from the list below. </p>

    <div class="container" :style="{ 'background-color': $parent.containerColor }">
      <template v-if="error != ''">
        <div class="row">
          <p>{{ error }}</p>
        </div>
      </template>
      <div class="row">
        <select class="input" v-model="languagePick">
          <option v-for="lang in $parent.langOptions" :key="lang">{{ lang }}</option>
        </select>

      </div>

      <div class="row">
        <button class="button" :style="{ 'background-color': $parent.buttonColor }" type="submit"
          @click="LangSetup()">Go</button>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
export default {
  name: 'LanguageSetup',
  props: {

  },
  data() {
    return {
      languagePick: "English",
      nameError: false,
      license: "",
      boxid: "",
      error: ""

    }
  },
  async mounted() {
    document.querySelector('button').backgroundColor = this.$parent.gameSettings.background;
    if (this.$parent.langOptions.length == 1) {
      this.languagePick = this.$parent.langOptions[0]
      this.LangSetup()
    }
  },
  methods: {
    async LangSetup() {
      this.$emit('langChoise', this.languagePick)
      this.$emit('updatestage', "")

    }
  }
}
</script>


